import { useEffect, useMemo, useRef, useState } from 'react';
import SiteLink from 'components/shared/sitelink/sitelink';
import useIsMobile from 'hooks/utils/useIsMobile';
import useSliderMomentum from 'hooks/utils/useSliderMomentum';
import ArrowRight from 'components/shared/arrow-right';
import ArrowLeft from 'components/shared/arrow-left';
import handleDrag from 'lib/utils/handleDrag';
import classNames from 'classnames';
import useCollectionSelectItemTracking from 'hooks/tracking/useCollectionSelectItemTracking';
import getShopifyData from 'lib/shopify/getShopifyData';
import { addLocaleToHref, reformatLocaleToContentfulLocale } from 'lib/utils/helpers';
import { useSettings } from 'hooks';
import useCollectionViewItemListTracking from 'hooks/tracking/useCollectionViewItemListTracking';
import { useRouter } from 'next/router';
import CardItem from './cardItem/cardItem';
import styles from './highlights.module.scss';
import InView from '../../shared/inView';

const InViewComponent = ({ isProducts, pushToDataLayerCb, index, product, children, className }) =>
  isProducts ? (
    <InView
      index={index}
      pushToDataLayerCb={pushToDataLayerCb}
      product={product}
      className={className}
    >
      {children}
    </InView>
  ) : (
    children
  );

const Highlights = ({ data, locale }) => {
  const { setSaveItemSelectionList } = useSettings();
  const { title, highlights, link, resources, sectionId } = data;
  const formattedLocale = reformatLocaleToContentfulLocale(locale);

  const [carouselPositioning, setCarouselPositioning] = useState({
    atLeftSide: true,
    atRightSide: highlights.length <= 4, // initially, it will be true only if all the items are showing in the first/only slide
  });
  // eslint-disable-next-line no-unused-vars
  const [dragging, setDragging] = useState(false);

  const [items, setItems] = useState([]);

  const isMobile = useIsMobile();
  const carouselRef = useRef();
  const router = useRouter();
  useSliderMomentum(carouselRef);
  const updateCarouselPositioning = () => {
    setCarouselPositioning({
      atLeftSide: carouselRef.current?.scrollLeft === 0,
      atRightSide:
        carouselRef.current?.scrollLeft + window?.innerWidth >= carouselRef.current?.scrollWidth,
    });
  };

  const localizedHighlights = useMemo(
    () =>
      highlights.filter(
        highlight =>
          (highlight.type !== 'PageProduct' && highlight.type !== 'PageProductBundle') ||
          highlight.availableLocales?.find(lcl => lcl === formattedLocale)
      ),
    [highlights, formattedLocale]
  );

  useEffect(() => {
    const updateProductData = (product, shopifyData) => {
      const updatedShopifyData = shopifyData?.find(
        ({ productSKU }) => productSKU === product?.shopifyData?.variants?.[0]?.sku
      );
      if (product.shopifyData?.variants?.[0]) {
        // eslint-disable-next-line no-param-reassign
        product.shopifyData.variants[0] = {
          ...product.shopifyData.variants[0],
          ...(updatedShopifyData?.productCompareAtPrice && {
            compareAtPrice: updatedShopifyData?.productCompareAtPrice,
          }),
          outOfStock: updatedShopifyData?.outOfStock,
          ...(updatedShopifyData?.productPrice && { price: updatedShopifyData?.productPrice }),
          ...(updatedShopifyData?.productTitle && { title: updatedShopifyData?.productTitle }),
        };
      }
    };

    const filterOutOfStock = arr => {
      const hasWebShop = resources?.settings?.localesWithWebshop?.onOrOff;
      if (!hasWebShop) return arr;

      const filteredProducts = arr.filter(item => {
        if (item.type === 'PageProduct') {
          return !item.shopifyData?.variants[0]?.outOfStock;
        }
        if (item.type === 'PageProductBundle' && item.products) {
          return item.products.filter(prod => !prod.shopifyData?.variants?.[0]?.outOfStock).length;
        }
        return true;
      });

      return filteredProducts;
    };

    const regroupShopifyProducts = shopifyData => {
      if (shopifyData?.length > 0) {
        // updating prices directly in filtered products which are stored in context
        localizedHighlights.forEach(prod => {
          if (prod.products) {
            const bundle = [];
            prod.products.forEach(item => {
              if (item.shopifyData) {
                bundle.push(
                  shopifyData.find(
                    ({ productSKU }) => item.shopifyData?.variants?.[0]?.sku === productSKU
                  )
                );
              }
            });
            // eslint-disable-next-line no-param-reassign
            prod.cardShopifyData = bundle;
          } else if (prod.shopifyData) {
            // eslint-disable-next-line no-param-reassign
            prod.cardShopifyData = shopifyData.find(
              ({ productSKU }) => prod.shopifyData.variants[0]?.sku === productSKU
            );
          }
        });
      }

      setItems(filterOutOfStock(localizedHighlights));
    };

    const shopifyDataRetrieval = async () => {
      const productIds = [];

      localizedHighlights.forEach(highlight => {
        if (highlight.type === 'PageProduct') {
          productIds.push(`gid://shopify/Product/${highlight.shopifyData?.variants[0].product_id}`);
        }

        highlight.products?.forEach(item =>
          productIds.push(`gid://shopify/Product/${item.shopifyData?.variants?.[0].product_id}`)
        );
      });

      if (productIds.length) {
        const pdpShopifyData = await getShopifyData({ productIds, locale });
        if (pdpShopifyData.length > 0) {
          localizedHighlights.forEach(highlight => {
            if (highlight.type === 'PageProductBundle') {
              highlight.products.forEach(bundleProd =>
                updateProductData(bundleProd, pdpShopifyData)
              );
            } else if (highlight.type === 'PageProduct') {
              updateProductData(highlight, pdpShopifyData);
            }
          });
        }

        regroupShopifyProducts(pdpShopifyData);
      } else {
        setItems(localizedHighlights);
      }
    };
    shopifyDataRetrieval();
  }, [localizedHighlights]);

  const carouselItemWidth = 500;
  let timeout;

  const dragHandler = e => {
    setTimeout(() => setDragging(true), 100);

    const { scrollLeft } = carouselRef.current;
    handleDrag(e, carouselRef, {
      leftSideCallback: (ev, delta) => {
        carouselRef.current.scrollLeft = scrollLeft - delta.x;
      },
      rightSideCallback: (ev, delta) => {
        carouselRef.current.scrollLeft = scrollLeft - delta.x;
      },
    });
  };

  const { pushToDataLayerCb } = useCollectionViewItemListTracking({
    products: localizedHighlights,
    itemListName: `Category: ${title}`,
  });

  const trackSelectItem = (highlight, position) => {
    if (highlight.type === 'PageProduct')
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useCollectionSelectItemTracking({
        product: {
          name: highlight.cardShopifyData?.productTitle,
          id: highlight.shopifyData?.variants?.[0]?.product_id,
          category: highlight.productSpecs.find(
            ({ type }) =>
              type === 'Producttype' || type === 'Type de produit' || type === 'Product type'
          )?.value,
          category2: highlight.productSpecs.find(
            ({ type }) =>
              type === 'Productcategorie' ||
              type === 'Catégorie de produit' ||
              type === 'Product Category'
          )?.value,
          category3: highlight.productSpecs.find(
            ({ type }) =>
              type === 'Subcategorie' || type === 'Sous-catégorie' || type === 'Sub Category'
          )?.value,
          price: highlight.cardShopifyDataData?.productPrice,
          position,
        },
        list: 'Highlights',
        setSaveItemSelectionList,
        locale,
      });
    else if (highlight.type === 'PageProductBundle') {
      highlight.cardShopifyData?.forEach((bundleProduct, index) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useCollectionSelectItemTracking({
          product: {
            name: bundleProduct?.productTitle,
            id: highlight.products[index].shopifyData?.variants?.[0]?.product_id,
            category: bundleProduct?.productVendor,
            price: bundleProduct?.productPrice,
            position,
          },
          list: 'Highlights',
          locale,
        });
      });
    }
  };

  if (!items.length) return null;

  return (
    <section id={sectionId || 'sectionHighlights'} className={styles.highlightsSection} key={title}>
      <h2
        className={classNames(
          highlights[0]?.type !== 'PageGeneral' && styles.title,
          highlights[0]?.type === 'PageGeneral' && styles.generalTitle
        )}
      >
        {title}
      </h2>
      {link && (
        <SiteLink
          locale={locale}
          className={styles.linkToMore}
          href={addLocaleToHref(link.link.slug, locale, router)}
        >
          {isMobile ? <ArrowRight /> : link.text}
        </SiteLink>
      )}
      <div
        className={styles.carouselWrapper}
        ref={carouselRef}
        onScroll={() => {
          clearTimeout(timeout);
          timeout = setTimeout(() => updateCarouselPositioning(carouselRef), 200);
        }}
        onTouchStart={ev => (isMobile ? dragHandler(ev) : null)}
        onPointerDown={ev => (!isMobile ? dragHandler(ev) : null)}
        onPointerUp={() => {
          setDragging(false);
        }}
      >
        <div
          className={classNames(styles.carousel, {
            [styles.centeredCarousel]:
              !isMobile &&
              localizedHighlights.length <= 4 &&
              highlights[0]?.type !== 'PageProduct' &&
              highlights[0]?.type !== 'PageProductBundle',
          })}
        >
          {!isMobile && localizedHighlights.length > 0 && (
            <>
              {!carouselPositioning.atRightSide && (
                <button
                  type="button"
                  className={styles.nextButton}
                  onClick={() => {
                    carouselRef.current.scrollBy(carouselItemWidth, 0);
                    updateCarouselPositioning();
                  }}
                >
                  <ArrowRight />
                </button>
              )}
              {!carouselPositioning.atLeftSide && (
                <button
                  type="button"
                  className={styles.previousButton}
                  onClick={() => {
                    carouselRef.current.scrollBy(-carouselItemWidth, 0);
                    updateCarouselPositioning();
                  }}
                >
                  <ArrowLeft />
                </button>
              )}
            </>
          )}
          {items.map((highlight, position) => (
            <InViewComponent
              key={highlight?.id}
              className={classNames(styles.productHighlight)}
              isProducts={
                highlight.type === 'PageProduct' || highlight.type === 'PageProductBundle'
              }
              product={highlight}
              index={position}
              pushToDataLayerCb={pushToDataLayerCb}
            >
              <CardItem
                className={classNames(
                  highlight.type === 'PageCollection' && styles.categoryHighlight,
                  highlight.type === 'PageGeneral' && styles.generalHighlight
                )}
                onClick={() => trackSelectItem(highlight, position)}
                locale={locale}
                itemData={highlight}
                globalModules={resources}
                itemIndex={position}
                pageTitle="Highlights"
                shopifyProduct={highlight.cardShopifyData}
              />
            </InViewComponent>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Highlights;
